<template>
  <div>
    <header>
      <ConnectWallet />
    </header>

    <!-- {{ dataCase }} -->

    <div :class="'z-0 bg-gray-200 ' + pageHeight" v-if="!getMenu">
      <div class="p-2 h-full">
        <div class="grid grid-cols-1 lg:grid-cols-4 h-full">
          <div class="bg-white h-full w-full border-2 border-gray-700">
            <div>
              <div class="">
                <div class="bg-gray-600 pl-5">
                  <h3 class="text-xl font-bold pt-5 pb-5 text-white">
                    <i
                      v-if="showDevice != 'device'"
                      class="fas fa-arrow-circle-left pr-5 cursor-pointer"
                      style="font-size: 24px"
                      @click="selectDevice('CHOOSE YOUR DEVICE')"
                    ></i>
                    {{ device }}
                  </h3>
                </div>
              </div>

              <hr />

              <div class="text-center" v-if="showDevice == 'model'">
                <div v-for="d in device_detail" :key="d">
                  <h4
                    class="text-lg text-gray-500 p-5 cursor-pointer"
                    @click="selectModel(d)"
                  >
                    {{ d.name }}
                  </h4>
                  <hr />
                </div>
              </div>

              <div class="" v-if="showDevice == 'case'">
                <div
                  v-for="d in model_list.model"
                  :key="d"
                  :class="'cursor-pointer ' + d.css"
                  @click="selectCase(d.name)"
                >
                  <div class="flex items-center">
                    <img class="h-44 p-5" :src="d.img" />
                    <h4 class="text-xl text-gray-500 p-5">{{ d.name }}</h4>
                  </div>

                  <hr />
                </div>
                <div
                  class="bg-gray-600 hover:bg-gray-500 mt-10 text-center cursor-pointer"
                  @click="selectColorCase()"
                >
                  <h3 class="text-xl font-bold pt-5 pb-5 text-white">Next</h3>
                </div>
              </div>

              <div class="" v-if="showDevice == 'color'">
                <div class="grid grid-cols-2 lg:grid-cols-2">
                  <div
                    v-for="d in model_name.color"
                    :key="d"
                    :class="'cursor-pointer ' + d.css"
                    @click="selectColor(d.img)"
                  >
                    <div class="flex justify-center">
                      <div>
                        <img class="p-5 h-48" :src="d.name" />

                        <h4 class="text-xl text-gray-500 pl-5 pt-3 pb-3">
                          {{ d.img }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <router-link to="/billingTopup">
                  <div
                    class="bg-green-600 hover:bg-green-900 mt-10 text-center cursor-pointer"
                  >
                    <h3 class="text-xl font-bold pt-5 pb-5 text-white">
                      CHECKOUT
                    </h3>
                  </div>
                </router-link>
              </div>

              <div
                class="grid grid-cols-2 divide-x"
                v-if="showDevice == 'device'"
              >
                <div
                  class="text-center cursor-pointer bg-gray-200"
                  @click="selectDevice('iPhone')"
                >
                  <div class="flex justify-center">
                    <div>
                      <img
                        class="h-48 lg:h-36 p-5"
                        src="https://www.casetify.com/img/phones/vector/iphone.png"
                      />

                      <h4 class="text-sm text-gray-500">iPhone</h4>
                    </div>
                  </div>

                  <hr class="mt-5" />
                </div>

                <div
                  class="text-center cursor-pointer"
                  @click="selectDevice('AirTag')"
                >
                  <div class="flex justify-center">
                    <div>
                      <img
                        class="h-48 lg:h-36 p-5"
                        src="https://www.casetify.com/img/phones/vector/airtag.png"
                      />

                      <h4 class="text-sm text-gray-500">AirTag</h4>
                    </div>
                  </div>

                  <hr class="mt-5" />
                </div>

                <div
                  class="text-center cursor-pointer"
                  @click="selectDevice('iPad')"
                >
                  <div class="flex justify-center">
                    <div>
                      <img
                        class="h-48 lg:h-36 p-5"
                        src="https://www.casetify.com/img/phones/vector/ipad.png"
                      />

                      <h4 class="text-sm text-gray-500">iPad</h4>
                    </div>
                  </div>

                  <hr class="mt-5" />
                </div>

                <div
                  class="text-center cursor-pointer"
                  @click="selectDevice('Macbook')"
                >
                  <div class="flex justify-center">
                    <div>
                      <img
                        class="h-48 lg:h-36 p-5"
                        src="https://www.casetify.com/img/phones/vector/laptop.png"
                      />

                      <h4 class="text-sm text-gray-500">Macbook</h4>
                    </div>
                  </div>

                  <hr class="mt-5" />
                </div>

                <div
                  class="text-center cursor-pointer"
                  @click="selectDevice('Android')"
                >
                  <div class="flex justify-center">
                    <div>
                      <img
                        class="h-48 lg:h-36 p-5"
                        src="https://www.casetify.com/img/phones/vector/android.png"
                      />

                      <h4 class="text-sm text-gray-500">Android</h4>
                    </div>
                  </div>

                  <hr class="mt-5" />
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div v-if="showDevice != 'color'">
              <div class="flex justify-center items-center w-full mt-5">
                <div class="text-3xl font-serif font-extrabold text-blue-900">
                  AI Creative
                </div>
              </div>
              <div class="mt-36 mb-36">
                <div class="w-full h-full">
                  <div class="flex justify-center items-center w-full">
                    <div>
                      <img class="w-96" :src="imgShow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full h-full" v-else>
              <div
                class="flex justify-center items-center"
                v-if="caseText == null || caseText == ''"
              >
                <div>
                  <div
                    class="bg-cover bg-center w-96"
                    :style="'background-image: url(' + preview + ')'"
                  >
                    <div class="flex items-center justify-center">
                      <div class="flex justify-center items-center">
                        <img
                          :src="img_bg"
                          alt="Image 1"
                          class="inset-0 object-cover ease-in-out w-auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center" v-else>
                <div>
                  <div
                    class="bg-cover bg-center w-96"
                    :style="'background-image: url(' + preview + ')'"
                  >
                    <div
                      class="bg-cover bg-center w-96"
                      :style="'background-image: url(' + img_bg + ')'"
                    >
                      <div class="flex justify-center items-end">
                        <div
                          :class="
                            'mt-80 mb-80 text-6xl font-bold font-sans ' +
                            textCss
                          "
                        >
                          {{ caseText }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="">
                <div>
                  <div class="flex justify-center">
                    <div class="w-full max-w-5xl">
                      <div class="">
                        <div
                          class="flex justify-center py-1 sm:py-3 px-3 rounded bg-gray-300 w-full"
                        >
                          <input
                            v-model="promptGenerate"
                            placeholder="Prompt"
                            class="flex-grow outline-none text-sm bg-transparent text-gray-700"
                            type="String"
                          /><span
                            class="w-20 flex-none text-sm px-6 mt-1 rounded text-gray-700 cursor-pointer"
                          >
                            Prompt
                          </span>
                        </div>

                        <div
                          class="flex justify-center py-1 sm:py-3 px-3 rounded bg-gray-300 mt-5"
                        >
                          <input
                            v-model="negative_prompt"
                            placeholder="Negative Prompt"
                            class="flex-grow outline-none text-sm bg-transparent text-gray-700"
                            type="String"
                          /><span
                            class="w-20 flex-none text-sm px-6 mt-1 rounded text-gray-700 cursor-pointer"
                          >
                            Negative Prompt
                          </span>
                        </div>

                        <div
                          @click="generateAI()"
                          class="cursor-pointer inline-block mt-5 text-xl px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-gray-600 to-gray-900"
                        >
                          <i class="fas fa-paint-brush mr-1"></i>Create Images
                        </div>
                      </div>

                      <div v-if="dataGen.length > 0" class="mt-10">
                        <div v-for="data in dataGen" :key="data" class="mt-2">
                          <div
                            class="grid grid-cols-2 gap-2 lg:grid-cols-2 md:grid-cols-2"
                          >
                            <div
                              class="transition cursor-pointer rounded-sm rounded"
                              v-for="s in data.output"
                              :key="s"
                            >
                              <div
                                @click="selectIMG(s)"
                                class="p-0.5 bg-opacity-25 bg-gradient-to-r from-blue-700 via-blue-500 to-blue-600 rounded"
                              >
                                <img :src="s" alt="Image 1" class="w-full" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <form>
                    <div class="form-group">
                      <label for="my-file">Select Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        @change="previewImage"
                        class="form-control-file"
                        id="my-file"
                      />
                    </div>
                  </form>

                  <div class="mt-5 max-w-lg">
                    <h3
                      class="text-xl font-bold text-transparent text-gray-600"
                    >
                      Text
                    </h3>
                    <div
                      class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                    >
                      <input
                        v-model="caseText"
                        placeholder="Text"
                        class="flex-grow outline-none text-sm bg-transparent text-gray-200"
                        type="String"
                        v-on:change="changeText()"
                      />
                      <span
                        class="w-20 flex-none text-xs px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                        >({{ caseText.length }}/8)
                      </span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter class="" />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";
import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

import deviceColor from "../../deviceColor";
import device from "../../device";
import modelDevice from "../../modelDevice";

import Generate from "@/api/generate";

export default {
  data() {
    return {
      imgShow:
        "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
      device: "CHOOSE YOUR DEVICE",
      showDevice: "device",
      model_name: {},
      device_detail: [],
      model_list: {},
      dataCase: {
        device: "",
        model: "",
        case: "",
        color: "",
      },
      preview: null,
      image: null,
      img_bg: null,
      caseText: "",
      textCss: "",
      promptGenerate: null,
      negative_prompt: null,
      dataGen: [],
      pageHeight: "h-screen",
    };
  },

  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getMenu", "getUser"]),

  methods: {
    previewImage: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },

    selectDevice(text) {
      this.dataCase.device = text;
      this.pageHeight = "h-screen";

      this.device = text;
      this.showDevice = "model";
      if (text == "iPhone") {
        this.device_detail = device.iPhone_List;
      } else if (text == "AirTag") {
        this.device_detail = device.AirTag_List;
      } else if (text == "iPad") {
        this.device_detail = device.iPad_List;
      } else if (text == "Macbook") {
        this.device_detail = device.Macbook_List;
      } else if (text == "CHOOSE YOUR DEVICE") {
        this.preview = null;
        this.showDevice = "device";
      } else {
        this.device_detail = device.iPhone_List;
      }
    },
    selectModel(model) {
      this.showDevice = "case";

      this.dataCase.model = model.name;

      var model_list = modelDevice.model.filter((m) => {
        return m.name == model.name;
      });

      this.model_list = model_list[0];
      this.imgShow = model_list[0].model[0].img;
    },

    selectCase(name) {
      this.dataCase.case = name;

      var a = this.model_list.model.map((m) => {
        if (m.name == name) {
          this.imgShow = m.img;

          m.css = " bg-gray-200";
          return m;
        } else {
          m.css = "";
          return m;
        }
      });

      this.model_list.model = a;
    },

    selectColorCase() {
      var model_name = this.model_list.model.filter((m) => {
        return m.css == " bg-gray-200";
      });

      if (model_name.length == 0) {
        var model_name = this.model_list.model.filter((m) => {
          return m.css == "bg-gray-200";
        });
      }

      var a = deviceColor.model.filter((m) => {
        return m.name == model_name[0].name;
      });

      this.pageHeight = "";
      this.showDevice = "color";
      this.model_name = a[0];
      this.imgShow = this.model_name.color[0].name;
      this.dataCase.color = this.model_name.color[0].img;
      this.img_bg = this.model_name.color[0].img_bg;
      this.textCss = this.model_name.color[0].textCss;
    },
    selectColor(name) {
      this.dataCase.color = name;

      this.model_name.color.filter((m) => {
        if (m.img == name) {
          this.imgShow = m.name;
          this.img_bg = m.img_bg;
          this.textCss = m.textCss;

          m.css = " bg-gray-200";
          return m;
        } else {
          m.css = "";
          return m;
        }
      });
    },
    changeText() {
      if (this.caseText.length > 8) {
        this.caseText = this.caseText.slice(0, 8);
      }
    },
    async generateAI() {
      let timerInterval;

      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 8000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
        }
      });

      await Generate.create({
        user_id: this.getUser._id,
        negative_prompt: this.negative_prompt,
        prompt: this.promptGenerate,
      })
        .then((res) => {
          this.getDataImage();

          this.promptGenerate = null;
          this.negative_prompt = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDataImage() {
      await Generate.genList({
        _id: this.getUser._id,
      })
        .then((res) => {
          this.dataGen = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async selectIMG(link_img) {
      this.preview = link_img;
    },
  },
  created() {
    this.getDataImage();
  },
};
</script>
